<template>
  <v-progress-linear v-if="loading" indeterminate/>
  <v-container v-else>
    <v-alert
      v-if="switchDisable"
      class="error-alert"
      max-width="350"
      color="red"
      dense
      type="error"
      dismissible
    >
      Excess number of active advisers. The created adviser will be disabled!
    </v-alert>
    <v-row justify="center">
      <v-col lg="9">
        <h2>Add New Adviser</h2>
        <v-form ref="form" lazy-validation class="adviser-form">
          <v-text-field
            label="User name"
            :rules="[required]"
            v-model="formData.auth.displayName"
            data-cy="input-username"
          />
          <v-text-field
            label="Email"
            :rules="[required]"
            v-model="formData.auth.email"
            data-cy="input-email"
          />
          <v-text-field
            label="Phone"
            :rules="[required]"
            v-model="formData.auth.phone"
            data-cy="input-phone"
          />
          <v-text-field
            label="Full name"
            v-model="formData.profile.fullName"
            data-cy="input-fullname"
          />
          <v-text-field
            label="Registration"
            v-model="formData.profile.registration"
            data-cy="input-registration"
          />
          <v-text-field
            label="Business Name"
            v-model="formData.profile.businessName"
            data-cy="input-business-name"
          />
          <v-text-field
            label="Business Registration"
            v-model="formData.profile.businessRegistration"
            data-cy="input-business-registration"
          />
          <v-text-field
            label="Business Email"
            v-model="formData.profile.businessEmail"
            data-cy="input-business-email"
          />
          <v-switch
            :disabled="switchDisable"
            v-model="formData.auth.disabled"
            label="Disabled"
          />
          <v-switch
            v-model="formData.auth.admin"
            label="Admin"
          />
          <v-btn
            color="primary"
            :loading="saving"
            @click="clickSave"
            data-cy="btn-save"
          >Create
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  createUser,
  getSettings,
  advisersList,
  getCurrentUserId,
} from '@/services/firebase';

export default {
  name: 'AdviserAdd',

  data() {
    return {
      savePressed: false,
      activeAdvisersCount: null,
      advisers: [],
      saving: false,
      switchDisable: false,
      formData: {
        auth: {
          displayName: '',
          phone: '',
          email: '',
          disabled: false,
          admin: false,
        },
        profile: {
          businessEmail: '',
          businessName: '',
          fullName: '',
          businessRegistration: '',
          registration: '',
        },
        recovered: false,
      },
      loading: true,
      required: (value) => !!value || 'Field required',
    };
  },

  computed: {
    ...mapGetters(['GET_LAST_NOTIFICATION']),
  },

  methods: {
    formValidation() {
      const isDisplayName = Boolean(this.formData.auth.displayName !== '');
      const isPhone = Boolean(this.formData.auth.phone !== '');
      const isEmail = Boolean(this.formData.auth.email !== '');

      const isValid = isDisplayName && isPhone && isEmail;

      return isValid;
    },

    async clickSave() {
      this.savePressed = false;
      this.saving = true;

      const validation = this.formValidation();

      if (!validation) {
        this.$refs.form.validate();
      } else {
        const currentUserId = await getCurrentUserId();

        const payload = {
          role: 'adviser',
          auth: {
            displayName: this.formData.auth.displayName,
            phone: this.formData.auth.phone,
            disabled: this.formData.auth.disabled,
            admin: this.formData.auth.admin,
            email: this.formData.auth.email,
          },
          profile: {
            businessEmail: this.formData.profile.businessEmail,
            businessName: this.formData.profile.businessName,
            fullName: this.formData.profile.fullName,
            businessRegistration: this.formData.profile.businessRegistration,
            registration: this.formData.profile.registration,
          },
          owner: currentUserId,
          recovered: false,
        };

        await createUser(payload);

        this.savePressed = true;
      }
    },

    freeAdvisersExist() {
      const isNotFreeAdvisers = Boolean(this.advisers.length
      >= this.activeAdvisersCount);

      if (isNotFreeAdvisers) {
        this.switchDisable = true;
        this.formData.auth.disabled = true;
      }
    },
  },

  async mounted() {
    const { settings } = await getSettings();

    const advisersCount = settings?.advisersCount;

    this.activeAdvisersCount = parseInt(advisersCount, 10);

    await advisersList()
      .then((response) => {
        response.forEach((u) => {
          const record = {
            uid: u.id,
            ...u.data(),
          };
          if (!record.auth.disabled) {
            this.advisers.push(record);
          }
        });
      });

    this.freeAdvisersExist();

    this.loading = false;
  },

  watch: {
    async GET_LAST_NOTIFICATION(val) {
      if (this.savePressed) {
        const isSuccess = await val?.content?.type;

        if (isSuccess === 'success') {
          this.$router.push({ name: 'AdvisersIndex' });
        }
      }

      this.savePressed = false;
      this.saving = false;
    },
  },
};
</script>

<style lang="scss">
.adviser-form {
  margin-bottom: 22px;
}

.without-indents {
  margin: 0;
  padding: 0;
}
.files {
  display: flex;
  flex-direction: column;

  &-table {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
}
</style>
