var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _c('v-container', [_vm.switchDisable ? _c('v-alert', {
    staticClass: "error-alert",
    attrs: {
      "max-width": "350",
      "color": "red",
      "dense": "",
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" Excess number of active advisers. The created adviser will be disabled! ")]) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('h2', [_vm._v("Add New Adviser")]), _c('v-form', {
    ref: "form",
    staticClass: "adviser-form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "User name",
      "rules": [_vm.required],
      "data-cy": "input-username"
    },
    model: {
      value: _vm.formData.auth.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "displayName", $$v);
      },
      expression: "formData.auth.displayName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email",
      "rules": [_vm.required],
      "data-cy": "input-email"
    },
    model: {
      value: _vm.formData.auth.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "email", $$v);
      },
      expression: "formData.auth.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Phone",
      "rules": [_vm.required],
      "data-cy": "input-phone"
    },
    model: {
      value: _vm.formData.auth.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "phone", $$v);
      },
      expression: "formData.auth.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Full name",
      "data-cy": "input-fullname"
    },
    model: {
      value: _vm.formData.profile.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "fullName", $$v);
      },
      expression: "formData.profile.fullName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Registration",
      "data-cy": "input-registration"
    },
    model: {
      value: _vm.formData.profile.registration,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "registration", $$v);
      },
      expression: "formData.profile.registration"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Business Name",
      "data-cy": "input-business-name"
    },
    model: {
      value: _vm.formData.profile.businessName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "businessName", $$v);
      },
      expression: "formData.profile.businessName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Business Registration",
      "data-cy": "input-business-registration"
    },
    model: {
      value: _vm.formData.profile.businessRegistration,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "businessRegistration", $$v);
      },
      expression: "formData.profile.businessRegistration"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Business Email",
      "data-cy": "input-business-email"
    },
    model: {
      value: _vm.formData.profile.businessEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "businessEmail", $$v);
      },
      expression: "formData.profile.businessEmail"
    }
  }), _c('v-switch', {
    attrs: {
      "disabled": _vm.switchDisable,
      "label": "Disabled"
    },
    model: {
      value: _vm.formData.auth.disabled,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "disabled", $$v);
      },
      expression: "formData.auth.disabled"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Admin"
    },
    model: {
      value: _vm.formData.auth.admin,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "admin", $$v);
      },
      expression: "formData.auth.admin"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.saving,
      "data-cy": "btn-save"
    },
    on: {
      "click": _vm.clickSave
    }
  }, [_vm._v("Create ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }